import React, {useEffect, useContext  } from 'react';
import { UserContext } from './UserContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { getAuth, onAuthStateChanged,getIdTokenResult  } from 'firebase/auth';
import HomePage from './HomePage';
import UserPage from './UserPage';
import Reportpage from './Reportpage';
import PrescriptionPage from './PrescriptionPage';
import RawDataPage from './RawDataPage';

// import LoginRegisterPage from './LoginRegisterPage';
import LoginPage from './LoginPage';
import RegistrationPage from './RegistrationPage';
import NavigationBar from './NavigationBar'; // 确保路径正确
import DiagnosePage from './DiagnoseReportPage';

import OrderPage from './OrderPage';

import Footer from './Footer';
import NetworkManager from './NetworkManager'; // 确保这个路径指向了您的 NetworkManager 类所在的位置

function App() {
  const { currentUser,setCurrentUser, setIsSuperAdmin,setUserProfile, userProfile } = useContext(UserContext);
  const firstName = userProfile ? userProfile.firstName : 'Unknown User';
  const displayName = firstName ? (firstName.length > 10 ? `${firstName.slice(0, 10)}...` : firstName) : '';

  const auth = getAuth();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // 设置临时 userProfile
        setUserProfile({
          firstName: '...',
          lastName: '...',
          gender: 'Male',
          birthDate: {_seconds: 1699401600, _nanoseconds: 0},
          birthTimeZone:"America/Los_Angeles",
          maritalStatus:null,
          accountStatus:null,
          WeightKG:0.0,
          HeightCM:0.0,
          BMI:0.0,
          AddressLine1:null,
          AddressLine2:null,
          AddressCity:null,
          AddressCountry:null,
          AddressProvince:null,
          AddressPostcode:null,
          clinicalUser:null,
          interpretingPhysician:null
        });
  
        // 定义一个函数来重试获取用户信息
        const retryFetchUserProfile = async (retryCount = 0) => {
          try {
            const userProfile = await NetworkManager.fetchUserDataProfile(`${user.uid}`);
            if (userProfile) {
              setCurrentUser(user);
              setUserProfile(userProfile);
              const idTokenResult = await getIdTokenResult(user);
              setIsSuperAdmin(!!idTokenResult.claims.superAdmin);
            } else if (retryCount < 10) {
              // 如果未获取到用户信息且重试次数少于10次，则延迟1秒后重试
              setTimeout(() => retryFetchUserProfile(retryCount + 1), 1000);
            }
          } catch (error) {
            console.error('Failed to fetch user profile:', error);
          }
        };
  
        // 首次尝试获取用户信息
        retryFetchUserProfile();
      } else {
        setCurrentUser(null);
        setUserProfile(null);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, [auth, setCurrentUser, setUserProfile,setIsSuperAdmin]);



  
  
  return (
    <Router>
      <NavigationBar
       currentUser={currentUser} 
       displayName={displayName} 
      />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/user" element={<UserPage />} />
        <Route path="/reports" element={<Reportpage />} />
        <Route path="/user-prescription" element={<PrescriptionPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/rawData" element={<RawDataPage />} />
        <Route path="/diagnose" element={<DiagnosePage />} />
        <Route path="/orderPage" element={<OrderPage />} />

        
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

