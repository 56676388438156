import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { Button, TextField,Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import loginBG from './assets/loginBG.png';
function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('User logged in successfully.');
      navigate('/user');
    } catch (error) {
      console.error('Login Error:', error);
      alert('Login Error: ' + error.message);
    }
  };
  const handleResetPassword = async () => {
    try {
      // 确保auth是Firebase Auth的实例，email是用户的电子邮件地址
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email has been sent, please check your inbox!");
      // navigate('/user'); // 通常在请求发送重置密码邮件后不会立即导航
    } catch (error) {
      if (error.code === "auth/missing-email") {
        alert("No email address entered. Please enter your email address.");
      }
      else {
        console.error('Reset Password Error:', error);
        alert('Reset Password Error: ' + error.message);
      }

    }
  };

  const handleToRegisterPage = async () => {
    navigate('/register');
  };
  return (
    <div style={{ marginTop: '120px', display: 'flex', alignItems: 'center', padding: '20px', backgroundColor: '#B8E8FE', backgroundImage: `url(${loginBG})`, height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <style>
        {`
          input::placeholder {
            color: #0291D4;
            opacity: 1; /* 保持完全不透明 */
          }
        `}
      </style>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '100px 10% 100px 10%', backgroundColor: 'rgba(255, 255, 255, 0.6)' }}>
        <h1>Sign in to Vitalitus</h1>
        <TextField label="Email" type="email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} style={{ marginBottom: '20px' }} InputProps={{
          style: {
            color: '#0291D4', // 设置输入框文字颜色
          },
        }}
          InputLabelProps={{
            style: {
              color: '#0291D4', // 设置标签文字颜色
            },
          }} />
        <TextField label="Password" type="password" variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)} style={{ marginBottom: '20px' }} InputProps={{
          style: {
            color: '#0291D4', // 设置输入框文字颜色
          },
        }}
          InputLabelProps={{
            style: {
              color: '#0291D4', // 设置标签文字颜色
            },
          }} />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <Button
              variant="contained"
              onClick={handleLogin}
              sx={{
                backgroundColor: '#003A57', // 设置背景颜色为 #003A57
                color: '#ffffff', // 设置文字颜色为白色
                fontSize: '18px',
                boxShadow: 'none', // 去除阴影
                borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
                padding: '12px 34px', // 添加适当的内边距
                '&:hover': {
                  backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                  color: '#ffffff', // 悬停时文字颜色仍然为白色
                  boxShadow: 'none', // 确保悬停时也没有阴影
                },
              }}
              endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
            >
              Sign In
            </Button>
          </div>
          {/* 
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '40px' }} />
            <Typography sx={{ px: 1 }}>OR</Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '40px' }} />
          </Box> */}

          <div style={{ marginLeft: '40px', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              component="a"
              href="#"
              sx={{
                color: '#0291D4',
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:hover': {
                  color: '#b04eff',
                },
                fontSize: '1.3825rem',
              }}
              onClick={handleResetPassword}
            >
              Forgot Password?
            </Typography>
          </div>
        </div>


      </div>
      <div style={{ flex: 1, padding: '100px 10% 200px 10%', borderRight: '1px solid #ccc' }}>
        <h1>Do not have an account? </h1>
        <p>Register right now!</p>
        
        <Button
          variant="contained"
          onClick={handleToRegisterPage}
          sx={{
            marginTop:'50px',
            backgroundColor: '#0260B5', // 设置背景颜色为 #003A57
            color: '#ffffff', // 设置文字颜色为白色
            fontSize: '18px',
            boxShadow: 'none', // 去除阴影
            borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
            padding: '12px 34px', // 添加适当的内边距
            '&:hover': {
              backgroundColor: '#003A57', // 悬停状态的背景颜色，稍微深一点
              color: '#ffffff', // 悬停时文字颜色仍然为白色
              boxShadow: 'none', // 确保悬停时也没有阴影
            },
          }}
          endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
        >
          Create account
        </Button>
      </div>



    </div>
  );
}

export default LoginPage;