// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// TODO: 替换为你自己的 Firebase 配置对象
const firebaseConfig = {
    apiKey: "AIzaSyDFFJzs4qVjVqsoTWkOVOM8zHb1jfoeGnQ",
    authDomain: "vitalitusfirebaseproject.firebaseapp.com",
    databaseURL: "https://vitalitusfirebaseproject-default-rtdb.firebaseio.com",
    projectId: "vitalitusfirebaseproject",
    storageBucket: "vitalitusfirebaseproject.appspot.com",
    messagingSenderId: "495358470612",
    appId: "1:495358470612:web:6f6e0ee5086f0b0255ce41",
    measurementId: "G-S8D6M4KCFJ"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export { auth };
