import React, { useState, useEffect } from 'react';

const DateTimePicker = ({ reportTimePair, index, onEndTimeChange }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [maxDateTime, setMaxDateTime] = useState('');

  useEffect(() => {
    let startTime;
    if (reportTimePair && reportTimePair[0]) {
      startTime = new Date(reportTimePair[0] * 1000);
    } else {
      startTime = new Date();
    }
    let placeholderTime = new Date(startTime.getTime() + 8 * 60 * 60 * 1000); // Add 8 hours directly to startTime

    // If placeholderTime is in the future, use the current time instead
    if (placeholderTime > new Date()) {
      placeholderTime = new Date();
    }


    // Convert to local string then to ISO string for datetime-local input
    let year = placeholderTime.getFullYear();
    let month = placeholderTime.getMonth() + 1; // getMonth() is zero-indexed
    let day = placeholderTime.getDate();
    let hours = placeholderTime.getHours();
    let minutes = placeholderTime.getMinutes();

    // Ensure double digits
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    

    // Format for datetime-local input
    setSelectedDate(`${year}-${month}-${day}T${hours}:${minutes}`);


    let currentTime = new Date();

    let currentyear = currentTime.getFullYear();
    let currentmonth = currentTime.getMonth() + 1; // getMonth() 是从 0 开始的
    let currentday = currentTime.getDate();
    let currenthours = currentTime.getHours();
    let currentminutes = currentTime.getMinutes();

    // 确保使用双位数表示
    currentmonth = currentmonth < 10 ? `0${currentmonth}` : currentmonth;
    currentday = currentday < 10 ? `0${currentday}` : currentday;
    currenthours = currenthours < 10 ? `0${currenthours}` : currenthours;
    currentminutes = currentminutes < 10 ? `0${currentminutes}` : currentminutes;
    // 设置 maxDateTime 为当前时间的 ISO 字符串
    setMaxDateTime(`${currentyear}-${currentmonth}-${currentday}T${currenthours}:${currentminutes}`);
  }, [reportTimePair]);

  const handleChange = (event) => {
    const newDate = new Date(event.target.value);

    // 更新 selectedDate 状态以反映输入框中的新值
    setSelectedDate(event.target.value);

    // 调用父组件的回调函数，传递新的 Date 对象和索引
    onEndTimeChange(newDate, index);

  };

  return (
    <div>
      <input
        type="datetime-local"
        value={selectedDate}
        onChange={handleChange}
        max={maxDateTime} // 使用 max 属性限制选择的最大日期和时间
      />
    </div>
  );
};

export default DateTimePicker;
