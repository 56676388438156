import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import LogoWhite from './assets/logowhite.png';
import downloadAppleIMG from './assets/downloadApple.png';
import downloadGoogleIMG from './assets/downGoogle.png';
import sendEmailPng from './assets/sendEmail.png';
import IconFacebook from './assets/IconFacebook.png';
import IconInstagram from './assets/IconInstagram.png';

// import LogoMedico from './assets/medico-supplies.png';
const footerStyles = {
    emailInputContainer: {
        display: 'flex',
        alignItems: 'center',
        border: '0px solid #ccc',
        borderRadius: '5px',
        overflow: 'hidden',
        width: '80%',
    },
    emailInput: {
        flex: 1,
        border: 'none',
        padding: '20px',
        borderRadius: '30px 0 0 30px',
        outline: 'none',
        fontSize: '16px',
        height:'24px'
    },
    sendButton: {
        backgroundImage: 'none',
        backgroundColor: '#b04eff',
        color: 'white',
        border: 'none',
        padding: '32px 30px',
        cursor: 'pointer',
        borderRadius: '0 30px 30px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height:'24px'
    },
};

function Footer() {
    const [email, setEmail] = useState('');
    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(String(email).toLowerCase());
    };

    const sendEmail = () => {
        if (!validateEmail(email)) {
            alert('Please enter a valid email address.');
            return;
        }

        const device = navigator.userAgent;
        const subject = 'Subscribe for Updates';
        const body = `System sent automatically.%0D%0A%0D%0ASent time: ${new Date().toLocaleString()}%0D%0ASent from: ${device}%0D%0AConsultation content: general (Subscribe for Updates)`;
        const mailtoLink = `mailto:terry@orthomedtech.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoLink;
    };

    const navigate = useNavigate();
    const [hover, setHover] = useState(null);
    const handleMouseEnter = (text) => {
        setHover(text);
    };
    const handleMouseLeave = () => {
        setHover(null);
    };
    const handleOrderDeviceClick = () => {
        navigate('/orderPage');
    };
    const handleHomeClick = () => {
        navigate('/');
    };
    const handleDownloadAppleImageClick = () => {
        window.open('https://apps.apple.com/app/vitalitus/id1583984803', '_blank');
    };
    const handleDownloadGoogleImageClick = () => {
        navigate('/orderPage');
    };
    const commonStyle = { cursor: 'pointer', color: 'white' };
    const hoverStyle = { color: '#b04eff' };
    // const handleMedicoCompanyImageClick = () => {
    //     window.open('https://www.medico-supplies.com/', '_blank');
    // };
    return (
        <footer style={{ color: 'white' }}>
            <div className="footer-content" style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#0081bc', padding: '50px' }}>
                <div style={{ width: '15%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                        src={LogoWhite} alt="logo"
                        style={{ width: '170px' }}
                    />
                    {/* <div style={{ width: '100px' }} /> 这个div用于创建两个logo之间的空间 */}
                    {/* <img
                        src={LogoMedico}
                        alt="logo-medico"
                        style={{ width: '100px', cursor: 'pointer' }}
                        onClick={handleMedicoCompanyImageClick}
                    /> */}
                    <img
                        src={downloadAppleIMG}
                        alt="Download Apple"
                        style={{ width: '200px', cursor: 'pointer', marginTop: '10px' }}
                        onClick={handleDownloadAppleImageClick}
                    />
                    <img
                        src={downloadGoogleIMG}
                        alt="Download Google"
                        style={{ width: '200px', cursor: 'pointer', marginTop: '10px' }}
                        onClick={handleDownloadGoogleImageClick}
                    />
                </div>
                <div style={{ width: '15%', textAlign: 'left' }}>
                    <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '23px', marginBottom: '20px', color: 'white' }}>
                        Useful Links
                    </Typography>

                    <p
                        style={hover === 'medicoSoft1' ? { ...commonStyle, ...hoverStyle } : commonStyle}
                        onMouseEnter={() => handleMouseEnter('medicoSoft1')}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleHomeClick}
                    >
                        Our Story
                    </p>
                    <p
                        style={hover === 'medicoSoft2' ? { ...commonStyle, ...hoverStyle } : commonStyle}
                        onMouseEnter={() => handleMouseEnter('medicoSoft2')}
                        onMouseLeave={handleMouseLeave}

                    >
                        Support
                    </p>
                    <p
                        style={hover === 'medicoSoft3' ? { ...commonStyle, ...hoverStyle } : commonStyle}
                        onMouseEnter={() => handleMouseEnter('medicoSoft3')}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleOrderDeviceClick}

                    >
                        VitalMark
                    </p>
                    <p
                        style={hover === 'medicoSoft4' ? { ...commonStyle, ...hoverStyle } : commonStyle}
                        onMouseEnter={() => handleMouseEnter('medicoSoft4')}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => window.open('https://apps.apple.com/app/vitalitus/id1583984803', '_blank')}
                    >
                        Vitalitus iOS App
                    </p>

                    {/* <p
                        style={hover === 'medicoSoft3' ? { ...commonStyle, ...hoverStyle } : commonStyle}
                        onMouseEnter={() => handleMouseEnter('medicoSoft3')}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => window.open('https://www.medico-supplies.com/products', '_blank')}
                    >
                        AutoFormer 5X Model Carver
                    </p>
                    <p
                        style={hover === 'medicoSoft4' ? { ...commonStyle, ...hoverStyle } : commonStyle}
                        onMouseEnter={() => handleMouseEnter('medicoSoft4')}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => window.open('https://www.medico-supplies.com/products', '_blank')}
                    >
                        SwiftScan PRO Handheld Scanner
                    </p> */}
                </div>
                {/* Logo Section */}

                <div style={{ width: '40%', textAlign: 'left' }}>
                    {/* <h3>Contact Us</h3>
                    <p>Toll free: 1-877-263-3426</p>
                    <p>Phone: 1-604-592-5109</p>
                    <p>Fax: 1-604-592-6081</p>
                    <p>Vitalitus Med<br />
                        #6 - 13025 84th Ave<br />
                        Surrey, BC V3W 1B3<br />
                        Canada</p> */}
                    <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '23px', marginBottom: '20px', color: 'white' }}>
                        Subscribe us for exclusive stories
                        and special promotions
                    </Typography>
                    <div style={footerStyles.emailInputContainer} className="email-input-container">
                        <input
                            style={footerStyles.emailInput}
                            type="email"
                            className="email-input"
                            id="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button className="send-button" style={footerStyles.sendButton} onClick={sendEmail}>
                            <img src= {sendEmailPng} alt="sendEmail" style={{ height: 'auto', width: '30px' }} />
                        </button>
                    </div>
                    <div id="socialDiv" style={{marginTop:'40px',display:'flex', justifyContent:'end',width:'80%',gap:'20px'}}>
                        <a href="https://vitalitusmed.com" target="_blank" rel="noopener noreferrer">
                            <img id="FBLOGO" src={IconFacebook} alt="facebook logo" style={{ height: '60px', width: 'auto' }} />
                        </a>
                        <a href="https://vitalitusmed.com" target="_blank" rel="noopener noreferrer">
                            <img src={IconInstagram} alt="IG logo" style={{ height: '60px', width: 'auto' }} />
                        </a>
                    </div>
                </div>
            </div>

            <div className="footer-bottom" style={{ textAlign: 'center', backgroundColor: 'white', color: '#003A57', fontSize: '20px' }}>
                <p>&copy; 2024 Vitalitus Med. All rights reserved.</p>
            </div>
            {/* <div style={{ height: '40px'}}></div> */}
        </footer>
    );
}

export default Footer;
