import React, { useState } from 'react';
import NetworkManager from './NetworkManager'; // Ensure correct path
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import loginBG from './assets/loginBG.png';
function RegistrationPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('Male');
  const [birthDate, setBirthDate] = useState('');
  const navigate = useNavigate();

  const handleRegister = async () => {
    if (!email || !password || !firstName || !lastName || !gender || !birthDate) {
      alert('All fields must be filled out.');
      return;
    }
    if (password.length < 6) {
      alert('Password must be at least 6 characters long.');
      return;
    }
    // 将生日储存为字符串，并储存用户注册时的时区信息。这样我们就知道用户所说的生日是哪个时区的了

    try {
      // 获取用户浏览器的时区
      const birthTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const userData = {
        email,
        password,
        firstName,
        lastName,
        gender,
        birthDate,
        birthTimeZone, // 储存浏览器时区信息（如 'America/New_York'）
      };
      await NetworkManager.registerUser(userData);
      navigate('/user');
    } catch (error) {
      console.error('Registration Error:', error);
      alert('Registration Error: ' + error.message);
    }
  };
  const navToLogin = async () => {
    navigate('/login');
  };
  // return (
  //   <div style={{marginTop: '140px'}}>
  //     <h1>Registration Page</h1>
  //     <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
  //     <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
  //     <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
  //     <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
  //     <select value={gender} onChange={(e) => setGender(e.target.value)}>
  //       <option value="Male">Male</option>
  //       <option value="Female">Female</option>
  //     </select>
  //     <input type="date" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
  //     <button onClick={handleRegister}>Register</button>
  //   </div>
  // );
  return (
    <div style={{ marginTop: '120px', display: 'flex', alignItems: 'center', padding: '20px', backgroundColor: '#B8E8FE', backgroundImage: `url(${loginBG})`, height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '100px 10% 100px 10%', backgroundColor: 'rgba(255, 255, 255, 0.6)' }}>
        <h1>Account Registration</h1>
        <TextField label="Email" type="email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} style={{ marginBottom: '20px' }} InputProps={{
          style: {
            color: '#0291D4', // 设置输入框文字颜色
          },
        }}
          InputLabelProps={{
            style: {
              color: '#0291D4', // 设置标签文字颜色
            },
          }} />
        <TextField label="Password" type="password" variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)} style={{ marginBottom: '20px' }} InputProps={{
          style: {
            color: '#0291D4', // 设置输入框文字颜色
          },
        }}
          InputLabelProps={{
            style: {
              color: '#0291D4', // 设置标签文字颜色
            },
          }} />
        <TextField label="First Name" variant="outlined" value={firstName} onChange={(e) => setFirstName(e.target.value)} style={{ marginBottom: '20px' }} InputProps={{
          style: {
            color: '#0291D4', // 设置输入框文字颜色
          },
        }}
          InputLabelProps={{
            style: {
              color: '#0291D4', // 设置标签文字颜色
            },
          }} />
        <TextField label="Last Name" variant="outlined" value={lastName} onChange={(e) => setLastName(e.target.value)} style={{ marginBottom: '20px' }} InputProps={{
          style: {
            color: '#0291D4', // 设置输入框文字颜色
          },
        }}
          InputLabelProps={{
            style: {
              color: '#0291D4', // 设置标签文字颜色
            },
          }} />
        <FormControl variant="outlined" style={{ marginBottom: '20px', minWidth: 120 }}>
          <InputLabel style={{ color: '#0291D4' }}>Gender</InputLabel>
          <Select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            label="Gender"
            style={{ color: '#0291D4' }}  // 设置选择框内的字体颜色
            MenuProps={{
              PaperProps: {
                style: {
                  color: '#0291D4',  // 设置下拉菜单内字体颜色
                },
              },
            }}
            inputProps={{
              style: {
                color: '#0291D4',  // 设置输入框内字体颜色
              },
            }}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
          </Select>
        </FormControl>
        <TextField label="Birth Date" type="date" variant="outlined" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} InputLabelProps={{
          shrink: true,
          style: { color: '#0291D4' } // 设置标签（Label）颜色
        }}
          InputProps={{
            style: {
              color: '#0291D4', // 设置输入框内文字颜色
            },
          }} style={{ marginBottom: '20px' }} />

        <Button
          variant="contained"
          onClick={handleRegister}
          sx={{
            backgroundColor: '#003A57', // 设置背景颜色为 #003A57
            color: '#ffffff', // 设置文字颜色为白色
            fontSize: '18px',
            boxShadow: 'none', // 去除阴影
            borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
            padding: '12px 34px', // 添加适当的内边距
            '&:hover': {
              backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
              color: '#ffffff', // 悬停时文字颜色仍然为白色
              boxShadow: 'none', // 确保悬停时也没有阴影
            },
          }}
          endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
        >
          Sign Up
        </Button>
      </div>
      <div style={{ flex: 1, padding: '100px 10% 200px 10%', borderRight: '1px solid #ccc' }}>
        <h1>Already have an account? </h1>
        <p>Login right now!</p>
        <Button
          variant="contained"
          onClick={navToLogin}
          sx={{
            marginTop: '50px',
            backgroundColor: '#0260B5', // 设置背景颜色为 #003A57
            color: '#ffffff', // 设置文字颜色为白色
            fontSize: '18px',
            boxShadow: 'none', // 去除阴影
            borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
            padding: '12px 34px', // 添加适当的内边距
            '&:hover': {
              backgroundColor: '#003A57', // 悬停状态的背景颜色，稍微深一点
              color: '#ffffff', // 悬停时文字颜色仍然为白色
              boxShadow: 'none', // 确保悬停时也没有阴影
            },
          }}
          endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
        >
          Login
        </Button>
      </div>





    </div>
  );
}

export default RegistrationPage;
