import React from 'react';
import ReportNavBar from './ReportNavBar';
import { useLocation } from 'react-router-dom';
function PrescriptionPage(){
    const location = useLocation();
    return (
        <div style={{ marginTop: '217px' }}>
            <ReportNavBar currentPath={location.pathname} />
        </div>
    );
}
export default PrescriptionPage;