class RecordAnalyzer {


  analyzeRecords(dataRecords, aiAudioResult) {
    if (!dataRecords || dataRecords.length === 0) {
      return null;
    }

    // 假设 dataRecords 已经按 startTime 排序
    const startRecord = dataRecords[0];
    const endRecord = dataRecords[dataRecords.length - 1];

    // 提取起始和结束记录的时间戳（假设时间戳在 startTime 和 endTime 的 _seconds 属性中）
    const recordStartTime = new Date(startRecord.startTime._seconds * 1000);
    const recordEndTime = new Date(endRecord.endTime._seconds * 1000);
    const totalStartTime = startRecord.startTime._seconds;
    const totalEndTime = endRecord.endTime._seconds;
    const totalDurationSeconds = totalEndTime - totalStartTime;
    const sampRate = startRecord.sampRate; // 假设所有记录的采样率相同
    // 计算总时长（秒）并转换为总采样数
    const totalSamplesEstimate = Math.ceil(totalDurationSeconds / sampRate);
    // 计算记录起始日期和时间
    const RecordStartDate = recordStartTime.toLocaleDateString().split('T')[0];
    var recordStart = recordStartTime.toLocaleTimeString();
    var recordEnd = recordEndTime.toLocaleTimeString();

    // 计算记录持续时间（小时数）
    const recordHours = (recordEndTime - recordStartTime) / (1000 * 60 * 60);
    // 初始化血氧饱和度数组和置信度数组
    const spo2ValuesArray = new Array(totalSamplesEstimate).fill(null);
    const spo2ConfiArray = new Array(totalSamplesEstimate).fill(null);
    // 初始化心率值数组和心率置信度数组
    const BPMValuesArray = new Array(totalSamplesEstimate).fill(null);
    const BPMConfiArray = new Array(totalSamplesEstimate).fill(null);
    // 初始化体温值数组和置信度数组
    const TempCValuesArray = new Array(totalSamplesEstimate).fill(null);
    //const TempCConfiArray = new Array(totalSamplesEstimate).fill(null);
    // 初始化体位 0:upsideDown 1:upright 2:lying right 3:lying left 4: prone 5: surpine 6: position changed
    const bodyOriArray = new Array(totalSamplesEstimate).fill(null);
    // 初始化最小值、最大值、总和以及不同饱和度水平下的样本数
    let minSPO2Value = Infinity;
    let maxSPO2Value = -Infinity;
    let SPO2below95Count = 0;
    let SPO2below90Count = 0;
    let SPO2below85Count = 0;
    let SPO2below80Count = 0;
    let SPO2below88Count = 0;
    let totalvalidSOP2Data = 0;
    // 初始化心率相关的统计数据
    let minBPMValue = Infinity;
    let maxBPMValue = -Infinity;
    let totalBPM = 0;
    let totalvalidBPMData = 0;
    // 初始化体温相关的统计数据
    let minTempValue = Infinity;
    let maxTempValue = -Infinity;
    let totalTemp = 0;
    let totalvalidTempData = 0;
    // 初始化整体区间数组
    const overallSurpinTimeSectionArray = [];
    const overallUnSurpinTimeSectionArray = [];
    const overallUpRightTimeSectionArray = [];
    // 初始化体位相关的统计数据
    let totalvalidbodyOri = 0;
    let totalSurpin = 0
    let totalUnSurpin = 0
    let totalUpRight = 0

    // 填充血氧饱和度数组和置信度数组
    dataRecords.forEach(record => {
      // 初始化子区间数组
      const SurpinTimeSectionArray = [];
      const UnSurpinTimeSectionArray = [];
      const UpRightTimeSectionArray = [];
      // 体位持续时间的区间计算
      let currentSectionStart = null;
      let currentSectionEnd = null;
      let currentSectionType = null;
      const recordStartIndex = Math.floor((record.startTime._seconds - totalStartTime) / sampRate);
      record.SPO2.values.forEach((value, index) => {
        const spo2Index = recordStartIndex + index;
        if (spo2Index < spo2ValuesArray.length) {
          spo2ValuesArray[spo2Index] = value;
          spo2ConfiArray[spo2Index] = record.SPO2.confi[index];
          // 更新最小值、最大值和总和
          if (value !== null) {
            minSPO2Value = Math.min(minSPO2Value, value);
            maxSPO2Value = Math.max(maxSPO2Value, value);
            totalvalidSOP2Data += 1;
          }

          // 更新不同饱和度水平下的样本数
          if (value < 95) SPO2below95Count++;
          if (value < 90) SPO2below90Count++;
          if (value < 88) SPO2below88Count++;
          if (value < 85) SPO2below85Count++;
          if (value < 80) SPO2below80Count++;
        }
      });


      record.heartRPM.values.forEach((value, index) => {
        const bpmIndex = recordStartIndex + index;
        if (bpmIndex < BPMValuesArray.length) {
          BPMValuesArray[bpmIndex] = value;
          BPMConfiArray[bpmIndex] = record.heartRPM.confi[index]; // 假设心率数据也有对应的置信度数组
          // 更新心率的最小值、最大值和总和
          if (value !== null) {
            minBPMValue = Math.min(minBPMValue, value);
            maxBPMValue = Math.max(maxBPMValue, value);
            totalBPM += value;
            totalvalidBPMData += 1;
          }
        }
      });
      record.TempC.values.forEach((value, index) => {
        const TempCIndex = recordStartIndex + index;
        if (TempCIndex < TempCValuesArray.length) {
          TempCValuesArray[TempCIndex] = value;
          //TempCConfiArray[TempCIndex] = record.TempC.confi[index]; 
          // 更新心率的最小值、最大值和总和
          if (value !== null) {
            minTempValue = Math.min(minTempValue, value);
            maxTempValue = Math.max(maxTempValue, value);
            totalTemp += value;
            totalvalidTempData += 1;
          }
        }
      });


      record.bodyOrient.orientation.forEach((value, index) => {
        const bodyOriIndex = recordStartIndex + index;
        if (bodyOriIndex < BPMValuesArray.length) {
          bodyOriArray[bodyOriIndex] = value;

          if (value !== null) {
            let sectionArray = null;
            if (value === 5) {
              totalSurpin += 1;
              sectionArray = SurpinTimeSectionArray;
            }
            else if (value === 1) {
              totalUpRight += 1;
              sectionArray = UpRightTimeSectionArray;
            }
            else if (value === 4 || value === 3 || value === 2 || value === 0) {
              totalUnSurpin += 1;
              sectionArray = UnSurpinTimeSectionArray;
            }
            if (sectionArray !== null) {
              if (currentSectionType !== value) {
                if (currentSectionType !== null) {
                  // 将上一个区间推入对应的数组
                  if (currentSectionType === 5) {
                    SurpinTimeSectionArray.push([currentSectionStart, currentSectionEnd + 3]);
                  } else if (currentSectionType === 1) {
                    UpRightTimeSectionArray.push([currentSectionStart, currentSectionEnd + 3]);
                  } else if (currentSectionType === 4 || currentSectionType === 3 || currentSectionType === 2 || currentSectionType === 0) {
                    UnSurpinTimeSectionArray.push([currentSectionStart, currentSectionEnd + 3]);
                  }
                }
                currentSectionStart = record.startTime._seconds + index * 3;
                currentSectionEnd = currentSectionStart;
                currentSectionType = value;
              } else {
                currentSectionEnd = record.startTime._seconds + index * 3;
              }
              totalvalidbodyOri += 1;
            }
          }
        }
      });
      // 添加最后一个区间
      if (currentSectionType !== null) {
        if (currentSectionType === 5) {
          SurpinTimeSectionArray.push([currentSectionStart, currentSectionEnd + 3]);
        } else if (currentSectionType === 1) {
          UpRightTimeSectionArray.push([currentSectionStart, currentSectionEnd + 3]);
        } else if (currentSectionType === 4 || currentSectionType === 3 || currentSectionType === 2 || currentSectionType === 0) {
          UnSurpinTimeSectionArray.push([currentSectionStart, currentSectionEnd + 3]);
        }
      }
      // 将子区间数组合并到整体区间数组中
      mergeSections(overallSurpinTimeSectionArray, SurpinTimeSectionArray);
      mergeSections(overallUnSurpinTimeSectionArray, UnSurpinTimeSectionArray);
      mergeSections(overallUpRightTimeSectionArray, UpRightTimeSectionArray);
      // console.log('SurpinTimeSectionArray:', SurpinTimeSectionArray);
      // console.log('UnSurpinTimeSectionArray:', UnSurpinTimeSectionArray);
      // console.log('UpRightTimeSectionArray:', UpRightTimeSectionArray);
    });

    // console.log('Overall SurpinTimeSectionArray:', overallSurpinTimeSectionArray);
    // console.log('Overall UnSurpinTimeSectionArray:', overallUnSurpinTimeSectionArray);
    // console.log('Overall UpRightTimeSectionArray:', overallUpRightTimeSectionArray);
    //血氧
    // const spo2ValuesArrayTest = [
    //   95, 95, 95, null, 95, 95, null, 95, 95, 95, // 前10个数据（稳定期，基线众数应为95）
    //   94, 93, 92, 91, 91, 91, null, 90, 91, 92, // 下降事件1
    //   95, 95, 95, 96, 95, 95, 95, 95, 95, 95, // 恢复期
    //   null, 96, 95, 94, 94, 94, 93, 92, 92, 91, // 下降事件2
    //   95, 96, 95, 95, 95, 95, 95, null, 95, 95  // 恢复期
    // ];
    const deOxygenEvents = calculateODI(spo2ValuesArray);
    let ODITotal = deOxygenEvents.length ?? 0;
    let ODI = recordHours !== 0 ? (ODITotal / parseFloat(recordHours)).toFixed(2) : 0;
    const modeAndAve = findModeAndAverageSPO2(spo2ValuesArray);
    const modeSPO2AsBaseline = modeAndAve.modeSPO2;
    const averageSPO2 = modeAndAve.averageSPO2;
    // 计算不同饱和度水平下的时间百分比
    const SPO2percentBelow95 = parseFloat((SPO2below95Count / totalvalidSOP2Data) * 100).toFixed(2);
    const SPO2percentBelow90 = parseFloat((SPO2below90Count / totalvalidSOP2Data) * 100).toFixed(2);
    const SPO2percentBelow88 = parseFloat((SPO2below88Count / totalvalidSOP2Data) * 100).toFixed(2);
    const SPO2percentBelow85 = parseFloat((SPO2below85Count / totalvalidSOP2Data) * 100).toFixed(2);
    const SPO2percentBelow80 = parseFloat((SPO2below80Count / totalvalidSOP2Data) * 100).toFixed(2);
    const SPO2below88TotalTimeSeconds = SPO2below88Count * 3; // 计算小于88%的氧气饱和度总时间（秒）
    var SPO2below88TotalTimeHours = SPO2below88TotalTimeSeconds / 3600.0; // 将秒转换为小时
    SPO2below88TotalTimeHours = parseFloat(SPO2below88TotalTimeHours.toFixed(2));

    // 心率
    const averageBPM = totalvalidBPMData > 0 ? (totalBPM / totalvalidBPMData).toFixed(2) : null;

    //体位
    const totalSurpinTime = parseFloat(((totalSurpin * 3) / 3600.0).toFixed(2));
    const totalUnSurpinTime = parseFloat(((totalUnSurpin * 3) / 3600.0).toFixed(2));
    const totalUpRightTime = parseFloat(((totalUpRight * 3) / 3600.0).toFixed(2));
    const totalSurpinPercent = totalvalidbodyOri !== 0 ? parseFloat((totalSurpin / totalvalidbodyOri) * 100).toFixed(2) : 0;
    const totalUnSurpinPercent = totalvalidbodyOri !== 0 ? parseFloat((totalUnSurpin / totalvalidbodyOri) * 100).toFixed(2) : 0;
    const totalUpRightPercent = totalvalidbodyOri !== 0 ? parseFloat((totalUpRight / totalvalidbodyOri) * 100).toFixed(2) : 0;
    //体温
    const averageBodyTemp = totalvalidTempData > 0 ? (totalTemp / totalvalidTempData).toFixed(2) : null;


    // 解析 AI 音频结果并计算呼吸统计数据
    let breathEvents = parseAiAudioResult(aiAudioResult);
    let totalBreaths = breathEvents === null ? 'Processing...' : breathEvents.length;
    let averageBreathsPerMinute = breathEvents === null ? 'Processing...' : (totalBreaths / (totalDurationSeconds / 60)).toFixed(2);
    if (aiAudioResult === '') {
      return {
        RecordStartDate,
        recordStartTime: recordStart,
        recordEndTime: recordEnd,
        recordHours: recordHours.toFixed(2), // 保留两位小数
        ODI: ODI,
        ODITotal: ODITotal,
        modeSPO2AsBaseline: modeSPO2AsBaseline,
        averageSPO2: averageSPO2,
        minSPO2Value: minSPO2Value,
        maxSPO2Value: maxSPO2Value,
        SPO2percentBelow95: SPO2percentBelow95,
        SPO2percentBelow90: SPO2percentBelow90,
        SPO2percentBelow88: SPO2percentBelow88,
        SPO2percentBelow85: SPO2percentBelow85,
        SPO2percentBelow80: SPO2percentBelow80,
        SPO2below88TotalTimeHours: SPO2below88TotalTimeHours,
        averageBPM: averageBPM,
        minBPMValue: minBPMValue,
        maxBPMValue: maxBPMValue,
        averageBodyTemp: averageBodyTemp,
        minTempValue: parseFloat(minTempValue).toFixed(2),
        maxTempValue: parseFloat(maxTempValue).toFixed(2),
        totalSurpinPercent: totalSurpinPercent,
        totalUnSurpinPercent: totalUnSurpinPercent,
        totalUpRightPercent: totalUpRightPercent,
        totalSurpinTime: totalSurpinTime,
        totalUnSurpinTime: totalUnSurpinTime,
        totalUpRightTime: totalUpRightTime,
        totalBreaths: 'No Breath Detected',
        averageBreathsPerMinute: 'No Breath Detected',
        apneaCount: 'No Breath Detected',
        hypopneaCount: 'No Breath Detected',
        rei: 'No Breath Detected',
        reiIndex: 'No Breath Detected',
        apneaIndex: 'No Breath Detected',
        hypopneaIndex: 'No Breath Detected',
        SurpinReiCountIndex: 'No Breath Detected',
        SurpinApneaCountIndex: 'No Breath Detected',
        SurpinHypopneaCountIndex: 'No Breath Detected',
        UnSurpinReiCountIndex: 'No Breath Detected',
        UnSurpinApneaCountIndex: 'No Breath Detected',
        UnSurpinHypopneaCountIndex: 'No Breath Detected',
        UpRightReiCountIndex: 'No Breath Detected',
        UpRightApneaCountIndex: 'No Breath Detected',
        UpRightHypopneaCountIndex: 'No Breath Detected',
        overallSurpinTimeSectionArray: overallSurpinTimeSectionArray,
        overallUnSurpinTimeSectionArray: overallUnSurpinTimeSectionArray,
        overallUpRightTimeSectionArray: overallUpRightTimeSectionArray,
      };
    }
    // 初始化 Apnea 和 Hypopnea 的计数
    let apneaCount = 0;
    let hypopneaCount = 0;
    // 初始化各个体位的 Apnea 和 Hypopnea 计数
    let SurpinApneaCount = 0;
    let SurpinHypopneaCount = 0;
    let UnSurpinApneaCount = 0;
    let UnSurpinHypopneaCount = 0;
    let UpRightApneaCount = 0;
    let UpRightHypopneaCount = 0;
    // 遍历 breathEvents，计算 Apnea 和 Hypopnea
    if (breathEvents !== null) {
      for (let i = 1; i < breathEvents.length; i++) {
        const previousEnd = breathEvents[i - 1].end;
        const currentStart = breathEvents[i].start;
        const interval = currentStart - previousEnd; // 时间间隔，单位为秒
        if (interval >= 10) {
          apneaCount++;
          // 计算特定体位的 Apnea
          if (isInSections(overallSurpinTimeSectionArray, previousEnd, currentStart, recordStartTime)) {
            SurpinApneaCount++;
          } else if (isInSections(overallUpRightTimeSectionArray, previousEnd, currentStart, recordStartTime)) {
            UpRightApneaCount++;
          } else {
            UnSurpinApneaCount++;
          }

        } else if (interval >= 3) {
          hypopneaCount++;
          // 计算特定体位的 Hypopnea
          if (isInSections(overallSurpinTimeSectionArray, previousEnd, currentStart, recordStartTime)) {
            SurpinHypopneaCount++;
          } else if (isInSections(overallUpRightTimeSectionArray, previousEnd, currentStart, recordStartTime)) {
            UpRightHypopneaCount++;
          } else {
            UnSurpinHypopneaCount++;
          }
        }
      }



      // 计算 REI (呼吸事件指数)
      const rei = apneaCount + hypopneaCount;
      const SurpinReiCount = SurpinApneaCount + SurpinHypopneaCount;
      const UnSurpinReiCount = UnSurpinApneaCount + UnSurpinHypopneaCount;
      const UpRightReiCount = UpRightApneaCount + UpRightHypopneaCount;
      // 计算每小时的 REI 指数
      const reiIndex = rei / recordHours;
      const apneaIndex = apneaCount / recordHours;
      const hypopneaIndex = hypopneaCount / recordHours;
      let SurpinDuration = recordHours * totalSurpinTime;
      let UnSurpinDuration = recordHours * totalUnSurpinTime;
      let UpRightDuration = recordHours * totalUnSurpinTime;
      const SurpinReiCountIndex = SurpinDuration !== 0 ? SurpinReiCount / SurpinDuration : 0;
      const SurpinApneaCountIndex = SurpinDuration !== 0 ? SurpinApneaCount / SurpinDuration : 0;
      const SurpinHypopneaCountIndex = SurpinDuration !== 0 ? SurpinHypopneaCount / SurpinDuration : 0;
      const UnSurpinReiCountIndex = UnSurpinDuration !== 0 ? UnSurpinReiCount / UnSurpinDuration : 0;
      const UnSurpinApneaCountIndex = UnSurpinDuration !== 0 ? UnSurpinApneaCount / UnSurpinDuration : 0;
      const UnSurpinHypopneaCountIndex = UnSurpinDuration !== 0 ? UnSurpinHypopneaCount / UnSurpinDuration : 0;
      const UpRightReiCountIndex = UpRightDuration !== 0 ? UpRightReiCount / UpRightDuration : 0;
      const UpRightApneaCountIndex = UpRightDuration !== 0 ? UpRightApneaCount / UpRightDuration : 0;
      const UpRightHypopneaCountIndex = UpRightDuration !== 0 ? UpRightHypopneaCount / UpRightDuration : 0;
      return {
        RecordStartDate: RecordStartDate,
        recordStartTime: recordStart,
        recordEndTime: recordEnd,
        recordHours: recordHours.toFixed(2), // 保留两位小数
        ODI: ODI,
        ODITotal: ODITotal,
        modeSPO2AsBaseline: modeSPO2AsBaseline,
        averageSPO2: averageSPO2,
        minSPO2Value: minSPO2Value,
        maxSPO2Value: maxSPO2Value,
        SPO2percentBelow95: SPO2percentBelow95,
        SPO2percentBelow90: SPO2percentBelow90,
        SPO2percentBelow88: SPO2percentBelow88,
        SPO2percentBelow85: SPO2percentBelow85,
        SPO2percentBelow80: SPO2percentBelow80,
        SPO2below88TotalTimeHours: SPO2below88TotalTimeHours,
        averageBPM: averageBPM,
        minBPMValue: minBPMValue,
        maxBPMValue: maxBPMValue,
        averageBodyTemp: averageBodyTemp,
        minTempValue: parseFloat(minTempValue).toFixed(2),
        maxTempValue: parseFloat(maxTempValue).toFixed(2),
        totalSurpinPercent: totalSurpinPercent,
        totalUnSurpinPercent: totalUnSurpinPercent,
        totalUpRightPercent: totalUpRightPercent,
        totalSurpinTime: totalSurpinTime,
        totalUnSurpinTime: totalUnSurpinTime,
        totalUpRightTime: totalUpRightTime,
        totalBreaths: totalBreaths,
        averageBreathsPerMinute: averageBreathsPerMinute,
        apneaCount: apneaCount,
        hypopneaCount: hypopneaCount,
        rei: rei,
        reiIndex: reiIndex.toFixed(2), // 保留两位小数
        apneaIndex: apneaIndex.toFixed(2),
        hypopneaIndex: hypopneaIndex.toFixed(2),
        SurpinReiCountIndex: SurpinReiCountIndex.toFixed(2), // 保留两位小数
        SurpinApneaCountIndex: SurpinApneaCountIndex.toFixed(2), // 保留两位小数
        SurpinHypopneaCountIndex: SurpinHypopneaCountIndex.toFixed(2), // 保留两位小数
        UnSurpinReiCountIndex: UnSurpinReiCountIndex.toFixed(2), // 保留两位小数
        UnSurpinApneaCountIndex: UnSurpinApneaCountIndex.toFixed(2), // 保留两位小数
        UnSurpinHypopneaCountIndex: UnSurpinHypopneaCountIndex.toFixed(2), // 保留两位小数
        UpRightReiCountIndex: UpRightReiCountIndex.toFixed(2), // 保留两位小数
        UpRightApneaCountIndex: UpRightApneaCountIndex.toFixed(2), // 保留两位小数
        UpRightHypopneaCountIndex: UpRightHypopneaCountIndex.toFixed(2), // 保留两位小数
        overallSurpinTimeSectionArray: overallSurpinTimeSectionArray,
        overallUnSurpinTimeSectionArray: overallUnSurpinTimeSectionArray,
        overallUpRightTimeSectionArray: overallUpRightTimeSectionArray,
      };

    }
    else {
      // 计算 REI (呼吸事件指数)
      const rei = 'Processing';
      // 计算每小时的 REI 指数
      const reiIndex = 'Processing';
      const apneaIndex = 'Processing';
      const hypopneaIndex = 'Processing';
      const SurpinReiCountIndex = 'Processing';
      const SurpinApneaCountIndex = 'Processing';
      const SurpinHypopneaCountIndex = 'Processing';
      const UnSurpinReiCountIndex = 'Processing';
      const UnSurpinApneaCountIndex = 'Processing';
      const UnSurpinHypopneaCountIndex = 'Processing';
      const UpRightReiCountIndex = 'Processing';
      const UpRightApneaCountIndex = 'Processing';
      const UpRightHypopneaCountIndex = 'Processing';
      return {
        RecordStartDate,
        recordStartTime: recordStart,
        recordEndTime: recordEnd,
        recordHours: recordHours.toFixed(2), // 保留两位小数
        ODI: ODI,
        ODITotal: ODITotal,
        modeSPO2AsBaseline: modeSPO2AsBaseline,
        averageSPO2: averageSPO2,
        minSPO2Value: minSPO2Value,
        maxSPO2Value: maxSPO2Value,
        SPO2percentBelow95: SPO2percentBelow95,
        SPO2percentBelow90: SPO2percentBelow90,
        SPO2percentBelow88: SPO2percentBelow88,
        SPO2percentBelow85: SPO2percentBelow85,
        SPO2percentBelow80: SPO2percentBelow80,
        SPO2below88TotalTimeHours: SPO2below88TotalTimeHours,
        averageBPM: averageBPM,
        minBPMValue: minBPMValue,
        maxBPMValue: maxBPMValue,
        averageBodyTemp: averageBodyTemp,
        minTempValue: parseFloat(minTempValue).toFixed(2),
        maxTempValue: parseFloat(maxTempValue).toFixed(2),
        totalSurpinPercent: totalSurpinPercent,
        totalUnSurpinPercent: totalUnSurpinPercent,
        totalUpRightPercent: totalUpRightPercent,
        totalSurpinTime: totalSurpinTime,
        totalUnSurpinTime: totalUnSurpinTime,
        totalUpRightTime: totalUpRightTime,
        totalBreaths: totalBreaths,
        averageBreathsPerMinute: averageBreathsPerMinute,
        apneaCount: apneaCount,
        hypopneaCount: hypopneaCount,
        rei: rei,
        reiIndex: reiIndex,
        apneaIndex: apneaIndex,
        hypopneaIndex: hypopneaIndex,
        SurpinReiCountIndex: SurpinReiCountIndex,
        SurpinApneaCountIndex: SurpinApneaCountIndex,
        SurpinHypopneaCountIndex: SurpinHypopneaCountIndex,
        UnSurpinReiCountIndex: UnSurpinReiCountIndex,
        UnSurpinApneaCountIndex: UnSurpinApneaCountIndex,
        UnSurpinHypopneaCountIndex: UnSurpinHypopneaCountIndex,
        UpRightReiCountIndex: UpRightReiCountIndex,
        UpRightApneaCountIndex: UpRightApneaCountIndex,
        UpRightHypopneaCountIndex: UpRightHypopneaCountIndex,
        overallSurpinTimeSectionArray: overallSurpinTimeSectionArray,
        overallUnSurpinTimeSectionArray: overallUnSurpinTimeSectionArray,
        overallUpRightTimeSectionArray: overallUpRightTimeSectionArray,
      };
    }

  }
}

// 合并区间函数
function mergeSections(overallArray, sectionArray) {
  if (sectionArray.length === 0) return;

  if (overallArray.length === 0) {
    Array.prototype.push.apply(overallArray, sectionArray);
    return;
  }

  let lastOverallSection = overallArray[overallArray.length - 1];

  sectionArray.forEach(section => {
    if (section[0] - lastOverallSection[1] <= 3) {
      // 如果新区间的开始时间与上一个整体区间的结束时间差小于等于3秒，合并它们
      lastOverallSection[1] = section[1];
    } else {
      // 否则，将新区间添加到整体区间数组中
      overallArray.push(section);
      lastOverallSection = section;
    }
  });
}
// 检查呼吸事件是否在特定的体位区间内
function isInSections(sectionArray, previousEnd, currentStart, recordStartTime) {
  // 将带小数的秒转换为 UNIX 时间戳
  const previousEndUnix = toUnixTimestamp(recordStartTime, previousEnd);
  const currentStartUnix = toUnixTimestamp(recordStartTime, currentStart);
  for (let section of sectionArray) {
    if (previousEndUnix >= section[0] && currentStartUnix <= section[1]) {
      return true;
    }
  }
  return false;
}
function toUnixTimestamp(recordStartDate, seconds) {
  // 获取该日期的 UNIX 时间戳（零时刻）
  const startDateUnix = Math.floor(recordStartDate.getTime() / 1000);
  // 将秒数加到零时刻的 UNIX 时间戳上
  return startDateUnix + Math.floor(seconds);
}
// function calculateODI(spo2ValuesArray) {
//   let deOxygenEvents = [];
//   const samplesBefore = 40; // 前120秒的样本数
//   const samplesAfter = 3; // 后10秒的样本数
//   for (let i = 1; i < spo2ValuesArray.length; i++) {
//     if (spo2ValuesArray[i] !== null && spo2ValuesArray[i - 1] !== null) {
//       if (spo2ValuesArray[i] < spo2ValuesArray[i - 1]) { // 发现下降的数据点
//         if (i >= samplesBefore && spo2ValuesArray.slice(i - samplesBefore, i).every(v => v !== null)) { // 检查之前40个数据是否连续
//           const O120AVE = spo2ValuesArray.slice(i - samplesBefore, i).reduce((acc, cur) => acc + cur, 0) / samplesBefore; // 计算O120AVE
//           if (spo2ValuesArray[i] < O120AVE - 3) { // 检查是否小于O120AVE-3
//             let isContinuousDrop = true;
//             for (let j = 1; j <= samplesAfter && i + j < spo2ValuesArray.length; j++) { // 向后检查3个数据点
//               if (spo2ValuesArray[i + j] === null || spo2ValuesArray[i + j] >= O120AVE - 3) {
//                 isContinuousDrop = false;
//                 break;
//               }
//             }
//             if (isContinuousDrop) { // 确认为缺氧事件
//               deOxygenEvents.push({
//                 start: i, // 记录开始索引
//                 end: i + samplesAfter // 记录结束索引
//               });
//               i += samplesAfter; // 从结束时间之后继续
//             }
//           }
//         }
//       }
//     }
//   }

//   // 计算ODI等其他逻辑...
//   // 返回缺氧事件列表或其他分析结果
//   return {
//     deOxygenEvents: deOxygenEvents,

//   }
// }

function calculateODI(spo2ValuesArray) {
  let deOxygenEvents = [];
  const threshold = 3; // 下降阈值
  let inDeOxygenEvent = false;
  let currentEvent = { start: null, end: null };

  // 计算众数作为基线
  const spo2ValuesFiltered = spo2ValuesArray.filter(v => v !== null);
  const mode = calculateMode(spo2ValuesFiltered);

  for (let i = 0; i < spo2ValuesArray.length; i++) {
    const currentSpo2 = spo2ValuesArray[i];

    if (currentSpo2 !== null && currentSpo2 <= mode - threshold) {
      if (!inDeOxygenEvent) {
        // 开始新的缺氧事件
        inDeOxygenEvent = true;
        currentEvent.start = i;
      }
      // 持续缺氧事件，更新结束点
      currentEvent.end = i;
    } else {
      if (inDeOxygenEvent) {
        // 缺氧事件结束，检查持续时间是否满足3个数据点
        if (currentEvent.end - currentEvent.start >= 2) {
          // 确认缺氧事件并保存
          deOxygenEvents.push({ start: currentEvent.start, end: currentEvent.end });
        }
        // 重置状态
        inDeOxygenEvent = false;
        currentEvent = { start: null, end: null };
      }
    }
  }

  // 检查最后一个事件
  if (inDeOxygenEvent && currentEvent.end - currentEvent.start >= 2) {
    deOxygenEvents.push({ start: currentEvent.start, end: currentEvent.end });
  }

  return deOxygenEvents; // 返回检测到的缺氧事件
}
function findModeAndAverageSPO2(spo2ValuesArray) {
  const frequencyMap = {}; // 记录每个SPO2值出现的次数
  let maxFrequency = 0; // 最大出现次数
  let modeSPO2 = []; // 众数数组
  let sum = 0; // SPO2值的总和
  let count = 0; // 非null值的数量

  spo2ValuesArray.forEach(value => {
    if (value !== null) { // 忽略null值
      sum += value; // 累加非null的SPO2值
      count++; // 增加非null值的计数

      frequencyMap[value] = (frequencyMap[value] || 0) + 1; // 更新出现次数

      // 更新最大出现次数和众数数组
      if (frequencyMap[value] > maxFrequency) {
        maxFrequency = frequencyMap[value];
        modeSPO2 = [value];
      } else if (frequencyMap[value] === maxFrequency) {
        // 如果当前值的出现次数等于最大出现次数，并且众数数组中尚未包含该值，则添加到众数数组中
        if (!modeSPO2.includes(value)) {
          modeSPO2.push(value);
        }
      }
    }
  });

  // 计算平均值
  const averageSPO2 = count > 0 ? (sum / count).toFixed(2) : null;

  // 如果众数数组只有一个元素，返回该元素；如果有多个众数，返回众数数组
  const modeResult = modeSPO2.length === 1 ? modeSPO2[0] : modeSPO2;

  return {
    modeSPO2: modeResult,
    averageSPO2: averageSPO2
  };
}
function parseAiAudioResult(aiAudioResult) {
  if (aiAudioResult == null) {
    return null
  }


  // const lines = aiAudioResult.trim().split('\n');
  // return lines.map(line => {
  //   const [start, end] = line.split('\t').map(Number);
  //   return { start, end };
  // });
  const lines = aiAudioResult.trim().split('\n');
  return lines.flatMap(line => {
    // 将行按制表符分割，然后将每个部分解析为数字
    const starts = line.split('\t').map(Number);
    // 将每个数字映射为包含 `start` 和 `end` 属性的对象，其中 `end` 与 `start` 相同
    return starts.map(start => ({ start, end: start }));
  });
}

// 计算数组的众数
function calculateMode(arr) {
  const frequency = {};
  let maxFreq = 0;
  let mode = null;

  for (let num of arr) {
    frequency[num] = (frequency[num] || 0) + 1;
    if (frequency[num] > maxFreq) {
      maxFreq = frequency[num];
      mode = num;
    }
  }

  return mode;
}
// 创建一个 NetworkManager 实例并赋值给一个变量
const recordsAnalyzerInstance = new RecordAnalyzer();

// 然后导出这个变量
export default recordsAnalyzerInstance;

