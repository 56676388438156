import React from 'react';
import { AppBar, Tabs, Tab } from '@mui/material';
import Typography from '@mui/material/Typography';
import { UserContext } from './UserContext';
import { DateTime } from 'luxon';
class ReportNavBar2 extends React.Component {
    // 使用 static contextType 来指定上下文类型
    static contextType = UserContext;

    render() {
        const { currentUser, userProfile } = this.context; // 使用 this.context 访问上下文的值

        return (
            <AppBar position="static" sx={{ background: 'white', height: "100px", padding: "10px", paddingLeft: '5%' }}>
                <Tabs
                    value={false} // 设置为 false 或 -1，使其不与任何 Tab 关联
                    aria-label="navigation tabs"
                    TabIndicatorProps={{ style: { backgroundColor: '#a1a1a1' } }}

                >
                    <Tab
                        label={
                            <span>
                                <Typography component="div" sx={{ fontSize: '16px', color: '#0192D8', textAlign: 'left' }}>
                                    Patient Name
                                </Typography>
                                <Typography component="div" sx={{ fontSize: '12px', color: '#a1a1a1', textAlign: 'left' }}>
                                    {userProfile.firstName}, {userProfile.lastName}
                                </Typography>
                            </span>
                        }
                    />
                    <Tab
                        label={
                            <span>
                                <Typography component="div" sx={{ fontSize: '16px', color: '#0192D8', textAlign: 'left' }}>
                                    Patient ID
                                </Typography>
                                <Typography component="div" sx={{ fontSize: '12px', color: '#a1a1a1', textAlign: 'left' }}>
                                    {currentUser.uid}
                                </Typography>
                            </span>
                        }
                    />
                    <Tab
                        label={
                            <span>
                                <Typography component="div" sx={{ fontSize: '16px', color: '#0192D8', textAlign: 'left' }}>
                                    D.O.B.
                                </Typography>
                                <Typography component="div" sx={{ fontSize: '12px', color: '#a1a1a1', textAlign: 'left' }}>
                                    {formatBirthDate (userProfile.birthDate,userProfile.birthTimeZone)}
                                        
                                </Typography>
                            </span>
                        }
                    />
                </Tabs>
            </AppBar>
        );
    }
}
export default ReportNavBar2;


const formatBirthDate = (birthDate, timeZone) => {
    if (!birthDate || !birthDate._seconds) return '';

    // 如果没有提供 timeZone，则使用默认值 "America/Los_Angeles"
    const timeZoneToUse = timeZone || 'America/Los_Angeles';

    // 将 Unix 时间戳（秒级）转换为 luxon 的 DateTime 对象，并使用指定时区
    const dateInTimeZone = DateTime.fromSeconds(birthDate._seconds).setZone(timeZoneToUse);

    // 格式化日期为 MM/dd/yy，例如：09/16/24
    return dateInTimeZone.toFormat('MM/dd/yyyy');
  };