import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [adminSearchUserId, setAdminSearchUserId] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [adminSearchUserProfile, setAdminSearchUserProfile] = useState(null);
  
  const [reportsTimePairs, setreportsTimePairs] = useState(null); // 添加 audioReportPathsDic 状态
  const [generatedAudioReport, setGeneratedAudioReport] = useState({ // 新添加的状态
    WAV_File: null,
    log_File: null,
    Data_Records: null
  });
  const logoutUser = () => {
    setCurrentUser(null);
    setUserProfile(null);
    setAdminSearchUserProfile(null);
    setIsSuperAdmin(false);
    setreportsTimePairs([]);
    setAdminSearchUserId(null);
    setGeneratedAudioReport({ WAV_File: null, log_File: null, Data_Records: null }); // 清空 generatedAudioReport
  };

  return (
    <UserContext.Provider value={{
      currentUser, setCurrentUser,
      adminSearchUserId, setAdminSearchUserId,
      adminSearchUserProfile,setAdminSearchUserProfile,
      userProfile, setUserProfile,
      isSuperAdmin,setIsSuperAdmin,
      reportsTimePairs, setreportsTimePairs,
      generatedAudioReport, setGeneratedAudioReport, // 提供 generatedAudioReport 和 setGeneratedAudioReport
      logoutUser
    }}>
      {children}
    </UserContext.Provider>
  );
};