import React from 'react';
import { AppBar, Tabs, Tab } from '@mui/material';
import { Link } from 'react-router-dom';

class ReportNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: this.getValueFromPath(props.currentPath) };
    }
    componentDidUpdate(prevProps) {
        // 当路径改变时更新选项卡的值
        if (prevProps.currentPath !== this.props.currentPath) {
            this.setState({ value: this.getValueFromPath(this.props.currentPath) });
        }
    }
// 根据路径返回对应的选项卡索引
getValueFromPath(path) {
    switch (path) {
        case '/user':
            return 0;
        case '/reports':
            return 1;
        case '/user-prescription':
            return 2;
        default:
            return 0;
    }
}
    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    render() {
        return (
            <AppBar position="static" sx={{
                background: 'linear-gradient( #21b6fe 0%, #019ee9 50%,#21b6fe 100%)',
            }}>
                <Tabs value={this.state.value} onChange={this.handleChange} aria-label="navigation tabs" TabIndicatorProps={{ style: { backgroundColor: '#a1a1a1' } }}>
                    <Tab label="Patient Info" sx={{
                        color: '#fff',
                        borderRight: '1px solid #000',
                        '&:last-child': { borderRight: 'none' },
                        '&.Mui-selected': { backgroundColor: '#b04eff', color: '#fff' },
                        '&:hover': { backgroundColor: '#b04eff' },
                    }}
                    component={Link} to="/user"
                    />
                    <Tab label="Diagnostic" sx={{
                        color: '#fff',
                        borderRight: '1px solid #000',
                        '&:last-child': { borderRight: 'none' },
                        '&.Mui-selected': { backgroundColor: '#b04eff', color: '#fff' },
                        '&:hover': { backgroundColor: '#b04eff' },
                    }}
                    component={Link} to="/reports"
                    />
                    <Tab label="Prescription" sx={{
                        color: '#fff',
                        borderRight: '1px solid #000',
                        '&:last-child': { borderRight: 'none' },
                        '&.Mui-selected': { backgroundColor: '#b04eff', color: '#fff' },
                        '&:hover': { backgroundColor: '#b04eff' },
                    }}
                    component={Link} to="/user-prescription"
                    />
                </Tabs>
            </AppBar>
        );
    }
}
export default ReportNavBar;