// UserPage.jsx
//import React from 'react';
import React, { useState, useEffect, useContext } from 'react';
import EditIcon from '@mui/icons-material/Edit'; // 引入编辑图标
import SaveIcon from '@mui/icons-material/Save';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // 确保正确导入 UserContext
import NetworkManager from './NetworkManager'; // 确保路径是正确的
import ReportNavBar from './ReportNavBar';
import { useLocation } from 'react-router-dom';
import { FormControl, Container, MenuItem, InputLabel, Select, Typography, Table, TextField, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Card, CardContent } from '@mui/material';
import { DateTime } from 'luxon';
function UserPage() {
  const navigate = useNavigate();
  const { currentUser, userProfile, setUserProfile } = useContext(UserContext);
  const [isFechingRecords, setIsFechingRecords] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(false); // 控制是否处于编辑模式
  const [editedProfile, setEditedProfile] = useState({}); // 存储编辑中的用户信息
  const [errorMessage, setErrorMessage] = useState(null);//显示错误信息
  const [invalidFields, setInvalidFields] = useState([]);//显示红色

  useEffect(() => {
    // 如果 currentUser 为空，则直接显示加载状态
    if (!currentUser) {
      setIsFechingRecords(true);
      return;
    }
    setIsFechingRecords(true);
    const fetchUserProfile = async () => {
      try {
        const userId = currentUser.uid;
        const userProfileData = await NetworkManager.fetchUserDataProfile(`${userId}`);
        setUserProfile(userProfileData);
      } catch (error) {
        setError('Failed to fetch user data');
        console.error('Failed to fetch user data:', error);
      } finally {
        setIsFechingRecords(false);
      }
    };

    fetchUserProfile();
  }, [currentUser, navigate, setUserProfile]);
  useEffect(() => {
    if (isEditing && userProfile) {
      setEditedProfile(userProfile); // 初始化编辑表单的值，仅在进入编辑模式时设置
      setInvalidFields([]);
      setErrorMessage(null);
    }
  }, [isEditing, userProfile]); // 依赖 isEditing，只有进入编辑模式时触发


  // 空依赖数组意味着这个 useEffect 只会在组件挂载时运行
  const maritalStatusOptions = [
    'Single',
    'Married',
    'Divorced',
    'Widowed',
    'Separated',
    'Other'
  ];
  const genderOptions = [
    'Male',
    'Female'
  ];
  const handleEditClick = () => {
    setIsEditing(!isEditing); // 切换编辑模式
  };
  const handleSaveClick = async () => {
    try {
      // 更新用户信息到数据库
      await NetworkManager.updateUserProfile(currentUser.uid, editedProfile);
      setUserProfile(editedProfile); // 更新上下文中的用户信息
      setInvalidFields([]);
      setErrorMessage(null);
      setIsEditing(false); // 退出编辑模式
    } catch (error) {
      setErrorMessage(error.message); // 获取错误信息
      setInvalidFields(error.errorLabelIDs || []); // 设置无效字段
    }
  };

  // 检查某个字段是否有错误
  const isFieldInvalid = (labelID) => {
    return Array.isArray(invalidFields) && invalidFields.includes(labelID);
  };
  // 新增取消功能
  const handleCancelClick = () => {
    setEditedProfile(userProfile); // 恢复为初始数据
    setIsEditing(false); // 退出编辑模式
    setInvalidFields([]);
    setErrorMessage(null);
  };

  const handleChange = (field, value) => {
    setEditedProfile({
      ...editedProfile,
      [field]: value,
    });
  };
  const handleSignOut = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      // 登出成功，重定向到首页
      navigate('/');
    } catch (error) {
      console.error('Sign Out Error:', error);
    }
  };
  // const handleDeleteAccount = async () => {

  //   const isConfirmed = window.confirm(
  //     "Are you sure you want to delete your account? " +
  //     "All of your data will be permanently deleted and cannot be recovered."
  //   );
  //   if (!isConfirmed) {
  //     // 用户点击了取消，不执行删除操作
  //     return;
  //   }
  //   const auth = getAuth();
  //   const userId = currentUser.uid;
  //   try {
  //     var status = await NetworkManager.deleteAccount(`users/${userId}`);
  //     // 登出成功，重定向到首页
  //     if (status === 200) {
  //       window.alert('Your account has been successfully deleted.');
  //       setCurrentUser(null);
  //       setUserProfile(null);
  //       await signOut(auth);

  //       navigate('/');
  //     }
  //     else {
  //       window.alert('Failed to delete your account. Please try again.');
  //     }
  //   } catch (error) {
  //     console.error('Delete account Error:', error);
  //     window.alert('Failed to delete your account. Please try again.');
  //   }
  // };


  if (!currentUser) {
    return <div style={{ margin: '200px', display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>No user data available.</div>;
  }

  else return (
    <div style={{ marginTop: '214px' }}>
      <ReportNavBar currentPath={location.pathname} />
      <Card style={{ margin: '10px 70px 20px 70px' }}>
        <Container component="main" style={{ padding: '20px', width: '80vw', marginLeft: '0px' }}>

          {/* 病人简历 Section */}
          <Typography variant="h5" gutterBottom>
            Basic Details
          </Typography>
          <div style={{ display: 'flex', gap: '6vw' }}>
            <TableContainer component={Paper} sx={{
              width: '60%',
              marginBottom: '20px',
              backgroundColor: '#fff',
              boxShadow: 'none', // 移除阴影
              border: 'none' // 移除边框
            }}>
              <div id='subView' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>
                {isFechingRecords ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : userProfile ? (
                  <Table aria-label="simple table" sx={{ border: 'none' }}>
                    <TableHead>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">First Name</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">
                          {isEditing ? (

                            <TextField
                              value={editedProfile.firstName || ''}
                              onChange={(e) => handleChange('firstName', e.target.value)}
                              label="Please enter your first name"
                              labelId="firstName-label"
                              error={isFieldInvalid('firstName-label')} // 根据是否存在错误高亮
                              InputLabelProps={{ shrink: true }} // 强制 label 悬浮在输入框上方
                            />
                          ) : (
                            userProfile.firstName
                          )}

                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Gender</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">
                          <FormControl fullWidth>
                            {isEditing ? (
                              <>
                                <InputLabel id="gender-label">please select</InputLabel>
                                <Select
                                  labelId="maritalStatus-label"
                                  label="gender-label"
                                  value={editedProfile.gender || 'Male'}
                                  onChange={(e) => handleChange('gender', e.target.value)}
                                  error={isFieldInvalid('gender-label')} // 根据是否存在错误高亮
                                >
                                  {genderOptions.map((status) => (
                                    <MenuItem key={status} value={status}>
                                      {status}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </>
                            ) : (
                              userProfile.gender
                            )}
                          </FormControl>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Patient ID</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">{currentUser.uid}</TableCell>
                      </TableRow>


                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Marital status</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">
                          <FormControl fullWidth>
                            {isEditing ? (
                              <>
                                <InputLabel id="marital-status-label">please select</InputLabel>
                                <Select
                                  labelId="maritalStatus-label"
                                  label="Marital Status"
                                  value={editedProfile.maritalStatus ||  'Other'}
                                  onChange={(e) => handleChange('maritalStatus', e.target.value)}
                                  error={isFieldInvalid('maritalStatus-label')} // 根据是否存在错误高亮
                                >
                                  {maritalStatusOptions.map((status) => (
                                    <MenuItem key={status} value={status}>
                                      {status}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </>
                            ) : (
                              <div>
                                {userProfile.maritalStatus ? userProfile.maritalStatus : 'Other'}
                              </div>
                            )}
                          </FormControl>

                        </TableCell>
                      </TableRow>



                    </TableBody>
                  </Table>
                ) : (
                  <p style={{ margin: '200px', display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>No user data available.</p>
                )}
              </div>
            </TableContainer>
            <TableContainer component={Paper} sx={{
              width: '60%',
              marginBottom: '20px',
              backgroundColor: '#fff',
              boxShadow: 'none', // 移除阴影
              border: 'none' // 移除边框
            }}>
              <div id='subView' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>
                {isFechingRecords ? (
                  <p></p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : userProfile ? (
                  <Table aria-label="simple table" sx={{ border: 'none' }}>
                    <TableHead>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Last Name</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left" colSpan={3}>
                          {isEditing ? (

                            <TextField
                              value={editedProfile.lastName || ''}
                              onChange={(e) => handleChange('lastName', e.target.value)}
                              label="Please enter your last name"
                              labelId="lastName-label"
                              error={isFieldInvalid('lastName-label')} // 根据是否存在错误高亮
                              InputLabelProps={{ shrink: true }} // 强制 label 悬浮在输入框上方
                            />
                          ) : (
                            userProfile.lastName
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Date Of Birth</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left" colSpan={3}>
                          {isEditing ? (
                            <TextField
                              value={
                                // editedProfile.birthDate && editedProfile.birthDate._seconds
                                //   ? new Date(editedProfile.birthDate._seconds * 1000).toISOString().slice(0, 10)
                                //   : ''
                                editedProfile.birthDate && editedProfile.birthDate._seconds
                                  ? DateTime.fromSeconds(editedProfile.birthDate._seconds)
                                    .setZone(editedProfile.birthTimeZone || 'America/Los_Angeles')  // 使用指定时区或默认时区
                                    .toFormat('yyyy-MM-dd')  // 格式化为 'YYYY-MM-DD'
                                  : ''
                              }
                              onChange={(e) => {
                                const selectedDateISO = e.target.value;
                                if (!selectedDateISO || selectedDateISO.length < 1) {
                                  return;
                                }
                                const timeZone = userProfile.birthTimeZone || 'America/Los_Angeles'; // 使用存储的时区或默认时区

                                // 使用 luxon 将 ISO 日期转换为指定时区的 UTC 时间
                                const birthDateTime = DateTime.fromISO(selectedDateISO, { zone: timeZone }).toUTC();

                                // 转换为 Firebase 时间戳格式：_seconds 和 _nanoseconds
                                const firebaseTimestamp = {
                                  _seconds: Math.floor(birthDateTime.toSeconds()),
                                  _nanoseconds: birthDateTime.toMillis() * 1e6 % 1e9, // 纳秒部分
                                };
                                // 更新为 Firebase 时间戳格式
                                handleChange('birthDate', firebaseTimestamp);
                              }}
                              type="date"
                              label="Please select birthday"
                              labelId="birthDate-label"
                              error={isFieldInvalid('birthDate-label')} // 根据是否存在错误高亮
                              inputProps={{
                                max: new Date().toLocaleDateString('en-CA'),
                              }}
                            />
                          ) : (
                            userProfile.birthDate && userProfile.birthDate._seconds
                              ? DateTime.fromSeconds(userProfile.birthDate._seconds).setZone(userProfile.birthTimeZone || 'America/Los_Angeles')  // 使用指定时区或默认时区
                                .toFormat('yyyy-MM-dd')  // 格式化为 'YYYY-MM-DD'
                              : 'No birth date available'

                          )}
                          {/* {isEditing ? (
                            <TextField
                              value={
                                editedProfile.birthDate && editedProfile.birthDate._seconds
                                  ? formatBirthDate(editedProfile.birthDate, editedProfile.birthTimeZone || 'America/Los_Angeles') // 使用指定或默认时区
                                  : ''
                              }
                              onChange={(e) => {
                                const selectedDate = new Date(e.target.value);

                                // 转换为 Firebase 时间戳格式
                                const firebaseTimestamp = {
                                  _seconds: Math.floor(selectedDate.getTime() / 1000), // 秒级时间戳
                                  _nanoseconds: 0 // 默认设置为 0
                                };

                                handleChange('birthDate', firebaseTimestamp); // 更新为 Firebase 时间戳格式
                              }}
                              type="date"
                              label="Please select birthday"
                              labelId="birthDate-label"
                              error={isFieldInvalid('birthDate-label')} // 根据是否存在错误高亮
                              inputProps={{
                                max: new Date().toLocaleDateString('en-CA'), // 限制最大日期为当前日期
                              }}
                            />
                          ) : (
                            userProfile.birthDate && userProfile.birthDate._seconds
                              ? formatBirthDate(userProfile.birthDate, userProfile.birthTimeZone || 'America/Los_Angeles') // 使用指定或默认时区
                              : 'No birth date available'
                          )} */}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Status</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left" colSpan={3}>
                          {
                            userProfile.accountStatus === 0 ? 'Inactive'
                              : userProfile.accountStatus === 1 ? 'Active'
                                : 'Active'
                          }</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Weight (kg)</TableCell>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Height (cm)</TableCell>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">BMI</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">
                          {isEditing ? (
                            <TextField
                              value={editedProfile.WeightKG || userProfile.WeightKG.toFixed(2)}
                              onChange={(e) => {
                                const inputValue = parseFloat(e.target.value); // 获取输入的值

                                handleChange('WeightKG', parseFloat(inputValue)); // 存储转换后的公斤值
                              }}
                              type="number"
                              label="Please enter number"
                              labelId="WeightKG-label"
                              error={isFieldInvalid('WeightKG-label')} // 根据是否存在错误高亮
                              inputProps={{ min: 0, max: 10000 }} // 设置最小值为 0，确保为正数
                            />
                          ) : (
                            `${(userProfile.WeightKG).toFixed(2)} kg`
                          )}


                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">
                          {isEditing ? (<TextField
                            value={editedProfile.HeightCM || userProfile.HeightCM.toFixed(2)}
                            onChange={(e) => {
                              const inputValue = parseFloat(e.target.value); // 获取输入的值
                              handleChange('HeightCM', inputValue); // 存储转换后的公斤值
                            }}
                            labelId="HeightCM-label"

                            type="number"
                            label="Please enter number"
                            inputProps={{ min: 0, max: 1000 }} // 设置最小值为 0，确保为正数
                            error={isFieldInvalid('HeightCM-label')} // 根据是否存在错误高亮
                          />
                          ) : (
                            `${(userProfile.HeightCM).toFixed(2)} cm`
                          )}
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">
                          {userProfile.BMI}
                        </TableCell>
                      </TableRow>


                    </TableBody>
                  </Table>
                ) : (
                  <p style={{ margin: '200px', display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>No user data available.</p>
                )}
              </div>
            </TableContainer>
          </div>




        </Container>
        <Container component="main" style={{ padding: '20px', width: '80vw', marginLeft: '0px' }}>

          {/* 病人简历 Section */}
          <Typography variant="h5" gutterBottom>
            Contact Details
          </Typography>
          <div style={{ display: 'flex', gap: '6vw' }}>
            <TableContainer component={Paper} sx={{
              width: '60%',
              marginBottom: '20px',
              backgroundColor: '#fff',
              boxShadow: 'none', // 移除阴影
              border: 'none' // 移除边框
            }}>
              <div id='subView' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>
                {isFechingRecords ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : userProfile ? (
                  <Table aria-label="simple table" sx={{ border: 'none' }}>
                    <TableHead>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Address line 1</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">
                          {isEditing ? (
                            <TextField
                              value={editedProfile.AddressLine1 || ''}
                              onChange={(e) => handleChange('AddressLine1', e.target.value)}
                              label="Please enter your address"
                              labelId="AddressLine1-label"
                              error={isFieldInvalid('AddressLine1-label')} // 根据是否存在错误高亮
                              InputLabelProps={{ shrink: true }} // 强制 label 悬浮在输入框上方
                            />
                          ) : (
                            userProfile.AddressLine1 ? userProfile.AddressLine1 : 'Please edit your Address'
                          )}

                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">City</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">
                          {isEditing ? (
                            <TextField
                              value={editedProfile.AddressCity || ''}
                              onChange={(e) => handleChange('AddressCity', e.target.value)}
                              label="Please enter your address"
                              labelId="AddressCity-label"
                              error={isFieldInvalid('AddressCity-label')} // 根据是否存在错误高亮
                              InputLabelProps={{ shrink: true }} // 强制 label 悬浮在输入框上方
                            />
                          ) : (
                            userProfile.AddressCity ? userProfile.AddressCity : 'Please edit your Address'
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Country</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">
                          {isEditing ? (
                            <TextField
                              value={editedProfile.AddressCountry || ''}
                              onChange={(e) => handleChange('AddressCountry', e.target.value)}
                              label="Please enter your address"
                              labelId="AddressCountry-label"
                              error={isFieldInvalid('AddressCountry-label')} // 根据是否存在错误高亮
                              InputLabelProps={{ shrink: true }} // 强制 label 悬浮在输入框上方
                            />
                          ) : (
                            userProfile.AddressCountry ? userProfile.AddressCountry : 'Please edit your Address'
                          )}
                        </TableCell>
                      </TableRow>



                    </TableBody>
                  </Table>
                ) : (
                  <p style={{ margin: '200px', display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>No user data available.</p>
                )}
              </div>
            </TableContainer>
            <TableContainer component={Paper} sx={{
              width: '60%',
              marginBottom: '20px',
              backgroundColor: '#fff',
              boxShadow: 'none', // 移除阴影
              border: 'none' // 移除边框
            }}>
              <div id='subView' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>
                {isFechingRecords ? (
                  <p></p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : userProfile ? (
                  <Table aria-label="simple table" sx={{ border: 'none' }}>
                    <TableHead>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Address line 2</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left" colSpan={3}>
                          {isEditing ? (
                            <TextField
                              value={editedProfile.AddressLine2 || ''}
                              onChange={(e) => handleChange('AddressLine2', e.target.value)}
                              label="Please enter your address"
                              labelId="AddressLine2-label"
                              error={isFieldInvalid('AddressLine2-label')} // 根据是否存在错误高亮
                              InputLabelProps={{ shrink: true }} // 强制 label 悬浮在输入框上方
                            />
                          ) : (
                            userProfile.AddressLine2 ? userProfile.AddressLine2 : 'Please edit your Address'
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Province / Territory</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left" colSpan={3}>
                          {isEditing ? (
                            <TextField
                              value={editedProfile.AddressProvince || ''}
                              onChange={(e) => handleChange('AddressProvince', e.target.value)}
                              label="Please enter your address"
                              labelId="AddressProvince-label"
                              error={isFieldInvalid('AddressProvince-label')} // 根据是否存在错误高亮
                              InputLabelProps={{ shrink: true }} // 强制 label 悬浮在输入框上方
                            />
                          ) : (
                            userProfile.AddressProvince ? userProfile.AddressProvince : 'Please edit your Address'
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Postcode</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left" colSpan={3}>
                          {isEditing ? (
                            <TextField
                              value={editedProfile.AddressPostcode || ''}
                              onChange={(e) => handleChange('AddressPostcode', e.target.value)}
                              label="Please enter your address"
                              labelId="AddressPostcode-label"
                              error={isFieldInvalid('AddressPostcode-label')} // 根据是否存在错误高亮
                              InputLabelProps={{ shrink: true }} // 强制 label 悬浮在输入框上方
                            />
                          ) : (
                            userProfile.AddressPostcode ? userProfile.AddressPostcode : 'Please edit your Address'
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ) : (
                  <p style={{ margin: '200px', display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>No user data available.</p>
                )}
              </div>
            </TableContainer>



          </div>




        </Container>
        <Container component="main" style={{ padding: '20px', width: '80vw', marginLeft: '0px' }}>

          {/* 病人简历 Section */}
          <Typography variant="h5" gutterBottom>
            Clinical details
          </Typography>
          <div style={{ display: 'flex', gap: '6vw' }}>
            <TableContainer component={Paper} sx={{
              width: '60%',
              marginBottom: '20px',
              backgroundColor: '#fff',
              boxShadow: 'none', // 移除阴影
              border: 'none' // 移除边框
            }}>
              <div id='subView' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>
                {isFechingRecords ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : userProfile ? (
                  <Table aria-label="simple table" sx={{ border: 'none' }}>
                    <TableHead>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Clinical user</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">{userProfile.clinicalUser}</TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>
                ) : (
                  <p style={{ margin: '200px', display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>No user data available.</p>
                )}
              </div>
            </TableContainer>
            <TableContainer component={Paper} sx={{
              width: '60%',
              marginBottom: '20px',
              backgroundColor: '#fff',
              boxShadow: 'none', // 移除阴影
              border: 'none' // 移除边框
            }}>
              <div id='subView' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>
                {isFechingRecords ? (
                  <p></p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : userProfile ? (
                  <Table aria-label="simple table" sx={{ border: 'none' }}>
                    <TableHead>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ fontSize: '18px', color: '#0291d4', paddingBottom: '0.5em', border: 'none' }} component="th" scope="row">Interpreting physician</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold', color: '#9b9898', paddingTop: '0px', paddingBottom: '0.3em' }} align="left">{userProfile.interpretingPhysician}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ) : (
                  <p style={{ margin: '200px', display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>No user data available.</p>
                )}
              </div>
            </TableContainer>
          </div>
        </Container>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          {errorMessage && (
            <div style={{ color: 'red', marginBottom: '20px' }}>
              {'Please fix the following errors: ' + errorMessage}
            </div>
          )}
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
          {isFechingRecords ? (
            <p></p>
          ) : (
            <div>
              <Button variant="contained"
                sx={{
                  backgroundColor: 'transparent',
                  color: '#0192D8',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  border: '2px solid #cccccc', // 灰色边框
                  borderRadius: '100px 100px 100px 100px',
                  '&:hover': {
                    backgroundColor: '#0192D8',
                    color: '#ffffff',
                    fontSize: '18px',
                    boxShadow: 'none',
                  },
                  marginRight: 2
                }}
                startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
                onClick={isEditing ? handleSaveClick : handleEditClick}>
                {isEditing ? 'Save' : 'Edit'}
              </Button>
              {isEditing && (
                <Button variant="contained"
                  sx={{
                    backgroundColor: 'transparent',
                    color: '#ff0000',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    border: '2px solid #ffcccc', // 红色边框
                    borderRadius: '100px 100px 100px 100px',
                    '&:hover': {
                      backgroundColor: '#ffcccc',
                      color: '#ffffff',
                      fontSize: '18px',
                      boxShadow: 'none',
                    },
                    marginLeft: 2
                  }}
                  onClick={handleCancelClick}>
                  Cancel
                </Button>
              )}
            </div>

          )}

        </div>
      </Card>

      {/* 账户管理 Section */}
      <Card style={{ backgroundColor: '#dddddf', margin: '10px 70px 20px 70px' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Account Management
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <Button variant="contained" sx={{
              fontSize: '18px', // 增大字体尺寸
              padding: '12px 24px' // 增加按钮填充以放大按钮
            }} color="primary" onClick={handleSignOut}>
              Sign Out
            </Button>
            {/* <Button variant="contained" sx={{

                backgroundColor: '#ee2121',
                '&:hover': {
                  backgroundColor: '#ca0f4d',
                },
                fontSize: '18px', // 增大字体尺寸
                padding: '12px 24px' // 增加按钮填充以放大按钮
              }}
                onClick={handleDeleteAccount}>
                Delete Account
              </Button> */}
          </div>
        </CardContent>
      </Card>
    </div>
  );

}

export default UserPage;
// const formatBirthDate = (birthDate, timeZone) => {
//   if (!birthDate || !birthDate._seconds) return '';

//   // 如果没有提供 timeZone，则使用默认值 "America/Los_Angeles"
//   const timeZoneToUse = timeZone || 'America/Los_Angeles';

//   // 将 Unix 时间戳（秒级）转换为 luxon 的 DateTime 对象，并使用指定时区
//   const dateInTimeZone = DateTime.fromSeconds(birthDate._seconds).setZone(timeZoneToUse);

//   // 格式化日期为 MM/dd/yy，例如：09/16/24
//   return dateInTimeZone.toFormat('MM/dd/yyyy');
// };